// svg img
import Group1177 from "../../assets/img/home/Basket_ball.svg";
import Group1175 from "../../assets/img/home/Football.svg";
import Group1176 from "../../assets/img/home/Football1.svg";
import Group1178 from "../../assets/img/home/Ball.svg";
import Group1179 from "../../assets/img/home/hockey.svg";
import Group1180 from "../../assets/img/home/hiking.svg";
import Group1181 from "../../assets/img/home/volleyball.svg";
import Group1182 from "../../assets/img/home/skiing.svg";
import Group1183 from "../../assets/img/home/baseball.svg";
import Group1184 from "../../assets/img/home/bounceball.svg";
import Group1185 from "../../assets/img/home/cycle.svg";
import Group1186 from "../../assets/img/home/racket.svg";

export const crouselData = [
    {
      id: 1,
      pic: Group1177,
    },

    {
      id: 2,
      pic: Group1175,
    },

    {
      id: 3,
      pic: Group1176,
    },

    {
      id: 4,
      pic: Group1178,
    },
    {
      id: 5,
      pic: Group1179,
    },
    {
      id: 6,
      pic: Group1180,
    },
    {
      id: 7,
      pic: Group1181,
    },
    {
      id: 8,
      pic: Group1182,
    },
    {
      id: 9,
      pic: Group1183,
    },
    {
      id: 10,
      pic: Group1184,
    },
    {
      id: 11,
      pic: Group1185,
    },
    {
      id: 12,
      pic: Group1186,
    },
  ];

