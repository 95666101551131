import Home from './Pages/Home'

const App = () => {
  return (
    <>
      <Home/>
    </>
  )
}

export default App